import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { RootLayout, AdminLayout } from "../layouts";
//----User Screens----//
//--------------------//
import {
  HomeScreen,
  LoginScreen,
  ResetPasswordScreen,
  ProfileScreen,
  BookDetails,
  CategoreyList,
  CategoryBooks,
  AuthorsList,
  AuthorBooks,
  SeriesBooks,
  Checkout,
  VerifyPaytabs,
  VerifyFawry,
  AccountPage,
} from "../screens/UserScreens";
//----Admin Screens----//
//---------------------//
import {
  AdminAnnouncements,
  AdminAuthors,
  AdminBooks,
  AddBookForm,
  EditBookForm,
  AdminCategories,
  AdminComments,
  AdminReportedComments,
  AdminRepliesReported,
  AdminDashboard,
  AdminSerieses,
  SerieForm,
  AdminUsers,
  AdminSupport,
  AdminTransactions,
  UserForm,
  AdminPlans,
  StatisticsScreen,
  SettingsScreen,
} from "../screens/AdminScreens";
//--------------------//
//--------------------//
import Page404 from "../screens/Page404";
import ErrorBoundary from "../screens/ErrorBoundary";
import Logout from "../screens/Logout";
import ProtectedRoute from "../middlewares/UserRoute";
import AdminRoute from "../middlewares/AdminRoute";
//------Loaders------//
//-------------------//
import {
  bookLoader,
  categoryBooksLoader,
  categoriesLoader,
  authorsLoader,
  authorBooksLoader,
  serieBooksLoader,
  adminBooksLoader,
  adminAuthorsLoader,
  adminCategoriesLoader,
  adminAnnouncementsLoader,
  adminSeriesesLoader,
  adminBooksInfoLoader,
  adminBookListLoader,
  adminPlansLoader,
  adminSupportLoader,
  reportedCommentsLoader,
  reportedRepliesLoader,
  adminSettingsLoader,
  adminStatisticsLoader,
} from "../api";

//-----------------------------------------------------------------------------------//
//-----------------------------------------------------------------------------------//
const router = createBrowserRouter(
  createRoutesFromElements([
    //----------------------------Root Layout
    <Route path="/" element={<RootLayout />} errorElement={<ErrorBoundary />}>
      {/* ------------simple routes--------------*/}
      <Route index element={<HomeScreen />} />,
      <Route path="categories">
        <Route index element={<CategoreyList />} loader={categoriesLoader} />
        <Route path=":id/books" element={<CategoryBooks />} loader={categoryBooksLoader} />
      </Route>
      <Route path="serieses/:id/books" element={<SeriesBooks />} loader={serieBooksLoader} />
      <Route path="books/:id" element={<BookDetails />} loader={bookLoader} />
      {/*-------------complex routes------------- */}
      <Route path="authors">
        <Route index element={<AuthorsList />} loader={authorsLoader} />
        <Route path=":id/books" element={<AuthorBooks />} loader={authorBooksLoader} />
      </Route>
      {/* logged user routes */}
      <Route path="user" element={<ProtectedRoute />}>
        <Route path="profile" element={<ProfileScreen />} />
        <Route path="account" element={<AccountPage />} />
        {/* <Route path="notifications" element={<NotificationsPage />} /> */}
        {/* <Route path="log" element={<UserLogs />} loader={userLogsLoader} /> */}
        {/* <Route path="favorites" element={<UserFavorites />} loader={userFavoritesLoader} /> */}
      </Route>
    </Route>,
    //----------------------------Admin Layout
    <Route
      path="admin"
      element={
        <AdminRoute>
          <AdminLayout />
        </AdminRoute>
      }
      errorElement={<ErrorBoundary />}>
      <Route index element={<AdminDashboard />} />
      <Route path="notifications" element={<h3>Notifications</h3>} />
      <Route path="announcements">
        <Route index element={<AdminAnnouncements />} loader={adminAnnouncementsLoader} />
      </Route>
      <Route path="users">
        <Route index element={<AdminUsers />} />
        <Route path="edit" element={<UserForm />} />
      </Route>
      <Route path="support">
        <Route index element={<AdminSupport />} loader={adminSupportLoader} />
      </Route>
      <Route path="categories">
        <Route index element={<AdminCategories />} loader={adminCategoriesLoader} />
      </Route>
      <Route path="transactions">
        <Route index element={<AdminTransactions />} />
      </Route>
      <Route path="comments">
        <Route index element={<AdminComments />} />
        <Route path="reported-comments" element={<AdminReportedComments />} loader={reportedCommentsLoader} />
        <Route path="reported-replies" element={<AdminRepliesReported />} loader={reportedRepliesLoader} />
      </Route>
      <Route path="authors">
        <Route index element={<AdminAuthors />} loader={adminAuthorsLoader} />
      </Route>
      <Route path="plans">
        <Route index element={<AdminPlans />} loader={adminPlansLoader} />
      </Route>
      <Route path="statistics">
        <Route index element={<StatisticsScreen />} loader={adminStatisticsLoader} />
      </Route>
      <Route path="serieses">
        <Route index element={<AdminSerieses />} loader={adminSeriesesLoader} />
        <Route path="add" element={<SerieForm />} loader={adminBookListLoader} />
        <Route path="edit" element={<SerieForm />} loader={adminBookListLoader} />
      </Route>
      <Route path="books">
        <Route index element={<AdminBooks />} loader={adminBooksLoader} />
        <Route path=":type/:id" element={<AdminBooks />} loader={adminBooksLoader} />
        <Route path="add" element={<AddBookForm />} loader={adminBooksInfoLoader} />
        <Route path="edit" element={<EditBookForm />} loader={adminBooksInfoLoader} />
      </Route>
      <Route path="settings">
        <Route index element={<SettingsScreen />} loader={adminSettingsLoader} />
      </Route>
    </Route>,
    //----------------------------No Layout
    <Route path="/login" element={<LoginScreen />} />,
    <Route path="reset-password" element={<ResetPasswordScreen />} />,
    <Route path="/register" element={<LoginScreen />} />,
    <Route path="/checkout" element={<Checkout />} />,
    <Route path="/verify/paytabs" element={<VerifyPaytabs />} />,
    <Route path="/verify/fawry" element={<VerifyFawry />} />,
    <Route path="/logout" element={<Logout />} />,
    <Route path="*" element={<Page404 />} />,
  ]),
);
export default router;
